<template>
  <div class="applicantForm">
    <p v-if="getFakeUser" style="color: #EB722E; margin-bottom: 8px;">{{ getFakeUser.name }}</p>
    <v-btn class="router_btn" @click="routerFunc">&#8592;</v-btn>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <p class="applicantForm__header">Анкета претендента</p>
      <button
        class="usual-btn usual-btn_bold" style="margin: 0"
        @click="blockConfirmFunc"
        v-if="!blocked && (!isExpert || isManager) && rowID !== '0'"
      >
        Завершить ввод данных
      </button>
      <button
        class="usual-btn usual-btn_bold" style="margin: 0"
        @click="unblockRow" v-if="blocked && isManager && !konvertConvert"
      >
        Разблокировать
      </button>
      <button
        class="usual-btn usual-btn_bold" style="margin: 0"
        @click="approveRow" v-if="isManager && konvertConvert && !adminAgreeStatus"
      >
        Одобрить
      </button>
      <button
        class="usual-btn usual-btn_bold" style="margin: 0"
        @click="unApproveRow" v-if="isManager && konvertConvert && adminAgreeStatus"
      >
        Снять одобрение
      </button>
    </div>
    <PersonInfoBlock :rowID.sync="rowID" :applicantBlocked="blocked" @getAll="getRow"/>
    <Block1 :specID.sync="specID" :konvertConvert="konvertConvert" :applicantBlocked="blocked" :rowID="rowID"/>
    <Block2 :rowID="rowID" :konvertConvert="konvertConvert" :applicantBlocked="blocked"/>
    <Block3 :rowID="rowID" :konvertConvert="konvertConvert" :applicantBlocked="blocked"/>
    <Block4 :rowID="rowID" :konvertConvert="konvertConvert" :applicantBlocked="blocked"/>
    <Block5 :rowID="rowID" :konvertConvert="konvertConvert" :applicantBlocked="blocked"/>
    <!--    <Block6 v-if="specID === 6" :konvertConvert="konvertConvert" :rowID="rowID" :applicantBlocked="blocked"/>-->
    <v-dialog
      v-model="blockConfirmDialog"
      width="455"
    >
      <v-card>
        <v-card-title>Подтверждение блокировки претендента</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="blockRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="blockConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PersonInfoBlock from "@/2024/components/applicants/applicantForm/korolevBlocks/PersonInfoBlock";
import Block1 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block1";
import Block2 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block2";
import Block3 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block3";
import Block4 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block4";
import Block5 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block5";
// import Block6 from "@/2024/components/applicants/applicantForm/korolevBlocks/Block6";
import axios from "axios";

export default {
  name: "ApplicantFormKorolev",
  components: {PersonInfoBlock, Block1, Block2, Block3, Block4, Block5}, //Block6
  data() {
    return {
      specID: 0,
      blocked: false,
      konvertConvert: false,
      adminAgreeStatus: false,
      blockConfirmDialog: false,
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
    }),
  },
  created() {
    if (this.rowID !== '0') {
      this.getRow();
    }
  },
  methods: {
    ...mapActions('notifications', {clearNotifications: 'clearNotifications'}),
    async getRow() {
      try {
        const res = await axios.get(`/api/2024/korolev/getById?rowId=${this.rowID}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.specID = res.data.mainSpec.usl.id;
          if (res.data.blockFlBlock) {
            this.blocked = true
          }
          if (res.data.konvertConvert) {
            this.konvertConvert = true;
          }
          if (res.data.adminAgreePatchAdminTbl5Agree) {
            this.adminAgreeStatus = true;
          } else {
            this.adminAgreeStatus = false;
          }
          console.log('getRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    blockConfirmFunc() {
      this.blockConfirmDialog = true;
    },
    
    async blockRow() {
      try {
        const res = await axios.put(`/api/2024/korolev/block?rowId=${this.rowID}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          await this.getRow();
          this.blocked = true;
          this.blockConfirmDialog = false;
          console.log('blockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async unblockRow() {
      try {
        const res = await axios.put(`/api/2024/korolev/unBlock?rowId=${this.rowID}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          await this.getRow();
          this.blocked = false;
          console.log('unblockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async approveRow() {
      try {
        const res = await axios.put(`/api/2024/korolev/approve?rowId=${this.rowID}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          await this.getRow();
          this.blocked = true;
          console.log('approveRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async unApproveRow() {
      try {
        const res = await axios.put(`/api/2024/korolev/unApprove?rowId=${this.rowID}`, '', {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          await this.getRow();
          this.blocked = true;
          console.log('unApproveRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    routerFunc() {
      if (this.$route.params.router === 'justCreated') { // только что созданные претенденты
        this.$router.go(-2);
      } else if (this.isExpert && !this.isManager) { // эксперт, но не админ
        this.$router.push({name: 'ApplicantsSelectionKorolev2024', params: {x: this.$route.params.x}})
      } else if (this.isManager && this.$route.params.routeName) {
        this.$router.push({
          name: this.$route.params.routeName,
          params: {x: this.$route.params.x, rowOrder: `${this.$route.params.rowOrder}`}
        })
      } else {
        this.$router.back();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.router_btn {
  height: 26px !important;
  padding: 0 8px !important;
  margin-bottom: 15px;
  width: 100px;
  cursor: url(../../../../assets/icons/cursor.svg), auto;
}

</style>