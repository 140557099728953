<template>
  <div v-if="rowID !== '0'">
    <div v-if="loading" style="min-height: 200px" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="blocks" v-else>
      <div
        class="blocks__headerDiv"
        style="display: flex; flex-direction: row"
        :style="!blockOpened ? 'border-bottom: none' : ''"
        @click="blockOpened = !blockOpened">
        <p class="headerText">
          Блок 5. Победы в конкурсах, олимпиадах, фестивалях и других научных, научно-технических
          мероприятиях
        </p>
        <img
          v-if="blockOpened"
          src="@/assets/icons/block_opened_blue.svg"
          class="btn_open"
          alt="" />
        <img v-else src="@/assets/icons/block_closed_blue.svg" class="btn_open" alt="" />
      </div>
      <div v-if="blockOpened && block5Data.length > 0">
        <Block5Item
          v-for="(item, j) in block5Data"
          :key="j"
          :value.sync="item"
          :rowID="rowID"
          :konvertConvert="konvertConvert"
          :order="j"
          :applicantBlocked="applicantBlocked"
          :eventTypeArray="eventTypeArray"
          :eventPrizePlaceArray="eventPrizePlaceArray"
          :statusArray="statusArray"
          @updateData="getAllBlock5" />
      </div>
      <Block5Creation
        v-if="blockOpened && blockEditingInProcess && !applicantBlocked"
        :applicantBlocked="applicantBlocked"
        :rowID="rowID"
        @updateData="getAllBlock5" />
      <div class="btnDiv" v-if="blockOpened && block5Data.length > 0">
        <button v-if="applicantBlocked || isExpert" class="usual-btn usual-btn_disabled">
          Добавить
        </button>
        <button v-else class="usual-btn" @click="addRow">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import Block5Item from '@/2024/components/applicants/applicantForm/korolevBlocks/blockItems/Block5Item';
  import Block5Creation from '@/2024/components/applicants/applicantForm/korolevBlocks/blockCreation/Block5Creation';

  export default {
    name: 'Block5',
    components: { Block5Creation, Block5Item },
    data() {
      return {
        loading: false,
        blockOpened: false,
        blockEditingInProcess: true,
        block5Data: [],
        eventTypeArray: [],
        statusArray: [],
        eventPrizePlaceArray: [],
      };
    },
    props: {
      rowID: {
        type: String,
        default: '',
      },
      applicantBlocked: {
        type: Boolean,
        default: false,
      },
      konvertConvert: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        isExpert: 'isExpert',
        // isCurator: 'isCurator',
      }),
    },
    created() {
      if (this.rowID !== '0') {
        this.getAllBlock5();
      }

      this.getSprEventType();
      this.getSprStatus();
      this.getDocs();

      if (this.isExpert) {
        this.blockOpened = true;
      }
    },
    methods: {
      async getAllBlock5() {
        this.loading = true;
        this.block5Data = [];
        try {
          const res = await axios.get(`/api/2024/korolev/block5/get?mainId=${this.rowID}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            if (res.data.length > 0) {
              this.block5Data = res.data;
              this.blockEditingInProcess = false;
            } else {
              this.blockEditingInProcess = true;
            }
            console.log('getAllBlock5 успех');
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },

      async getSprEventType() {
        try {
          const res = await axios.get(`/api/2024/spr/textValKorolev/get?block=5&position=1`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.eventTypeArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getSprEventType успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprStatus() {
        try {
          const res = await axios.get(`/api/2024/spr/textValKorolev/get?block=5&position=2`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.statusArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getSprStatus успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getDocs() {
        try {
          const res = await axios.get(`/api/2024/spr/textValKorolev/get?block=5&position=3`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.eventPrizePlaceArray.push({
                text: res.data[i].value,
                value: res.data[i].id,
              });
            }
            console.log('getDocs успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      addRow() {
        this.blockEditingInProcess = true;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
